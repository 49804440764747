<template>
  <div>
    <vx-card class="directors-card newsletter-card">
      <div class="header-table">
        <div class="w-full mb-8">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-3/4">
              <div class="card-title">
                <h2>Newsletters</h2>
              </div>
            </div>
            <div class="w-full md:w-1/4">
              <vs-button
                color="primary"
                type="filled"
                @click="addNewsletter"
                class="w-full md:w-auto float-right"
              >Create Newsletters
              </vs-button>
            </div>
          </div>
          <div class="flex flex-wrap items-center mt-10 lg:mt-0">
            <div class="w-full lg:w-3/4 flex flex-wrap header-table-row">
              <div class="w-full md:w-1/2">
                <vs-input
                  class="w-full search-input"
                  icon-pack="feather"
                  icon="icon-search"
                  v-model="searchQuery"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="w-full lg:w-1/4">
              <div class="select-wrapper mt-0 no-border">
                <label>Sort By:</label>
                <v-select
                  :options="sortOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="sortByFilter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="newslettersData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="thead">
          <vs-th>Date Published</vs-th>
          <vs-th>Author</vs-th>
          <vs-th>Room</vs-th>
          <vs-th>Title</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>&nbsp;</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.date" class="date-published">
              <div
                v-if="tr.hasPublished == true"
              >{{ moment(tr.createdAt).format('DD/MM/YY') }}
              </div>
              <div v-else></div>
            </vs-td>
            <vs-td :data="tr.author" class="author">
              <div class="flex items-center">
                <vs-avatar :src="tr.authorDetails[0].profileImage" class="mr-5"/>
                {{ tr.author | capitalize }}
              </div>
            </vs-td>
            <vs-td :data="tr.roomCount">{{ tr.roomCount }}</vs-td>
            <vs-td :data="tr.title">
              <a
                @click="viewHandler(tr)"
                class="underline"
              >{{ tr.title | capitalize }}</a>
            </vs-td>
            <vs-td
              :data="tr.hasPublished"
            >{{ tr.hasPublished == true ? "Published" : "Draft" }}
            </vs-td>
            <vs-td :data="tr.noChildren">{{ tr.noChildren }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="pagination-wrapper">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import vSelect from "vue-select";
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      serverResponded: false,
      dataTableParams: {
        search: "",
        sort: "newsletters.date",
        dir: "desc",
        limit: 5,
        page: 1,
      },
      newslettersData: [],
      sortByFilter: {label: "Date", value: "newsletters.date"},
      sortOptions: [
        {label: "Date", value: "newsletters.date"},
        {label: "Room", value: "newsletters.rc"},
        {label: "Author", value: "newsletters.authorDetails.fullName"},
        {label: "Status", value: "newsletters.hasPublished"},
      ],
      searchQuery: "",
      awaitingSearch: false,
    };
  },
  methods: {
    ...mapActions("newsletter", ["fetchNewsletterList"]),
    moment(date) {
      return moment(date);
    },
    getNewslettersList() {
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.sort = this.sortByFilter.value;
      this.dataTableParams.directorId = this.director.id;
      this.$vs.loading();
      this.fetchNewsletterList(this.dataTableParams)
        .then((res) => {
          this.newslettersData = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if ( err.response.status === 403 ) {
            Swal
              .fire({
                title: "No Access",
                html: `
                  <div>
                    You dont have permission to visit this page. Please upgrade your package.
                  </div>`,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
              })
              .then((result) => {
                this.$router.push('/director/upgrade-package');
              });
          }
        });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNewslettersList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getNewslettersList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNewslettersList();
    },
    viewHandler(data) {
      this.$router.push({
        name: "newsletters-view",
        params: {id: data._id},
        query: {title: data.title},
      });
    },
    addNewsletter() {
      this.$router.push({name: "newsletters-create"});
    },
  },
  mounted: {},
  computed: {
    director: function () {
      return this.$store.state.AppActiveUser;
    },
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getNewslettersList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNewslettersList();
      }
    },
    // "dataTableParams.sort": function (newSort, oldSort) {
    //   if (newSort !== oldSort) {
    //     this.dataTableParams.page = 1;
    //     this.dataTableParams.sort = newSort;
    //     this.getNewslettersList();
    //   }
    // },
    searchQuery: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getNewslettersList();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    sortByFilter(obj) {
      this.getNewslettersList();
    },
  },
  created() {
    this.getNewslettersList();
  },
};
</script>
